import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppConfigService } from '../../core/services/config.service';
import { ApplicationInfoService } from '../../core/translate/application-info.service';
import { TransactionResult } from '../../core/services/interfaces/transaction-result';
import { TranslateApplicationService } from '../../core/translate/translate-application-service';
import { Application } from '../../core/translate/models/application';
import { CognitoService } from '../../core/services/cognito/cognito.service';
import { NavigationService } from 'src/app/core/components/navigation/navigation.service';
import { AppNavigationModel } from 'src/app/navigation/navigation.model';
import { EmployeeService } from '../content/sections/services/employee-service';
import { IEmployeefromsap } from '../content/sections/Interfaces/IEmployeefromsap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IRoles } from '../content/sections/Interfaces/IRoles';
import { ISites } from '../content/sections/Interfaces/ISites';
import { SitesService } from '../../main/content/sections/services/sites-services';
import { ActiveDirectoryService } from '../../main/content/sections/services/activedirectory-service';
import { IEmployeesInActiveDirectory } from '../content/sections/Interfaces/IEmployeesInActiveDirectory';
import { EnvironmentsService } from '../content/sections/services/environments-services';
declare let jQuery: any;

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class AppToolbarComponent {
	isAdminUser = false;
	userStatusOptions: any[];
	languages: Array<any> = [];
	selectedLanguage: any;
	showLoadingBar: boolean;
	horizontalNav: boolean;
	username: string;
	isAuthenticated: boolean;
	listSites: Array<ISites> = [];
	currentSite: Array<ISites> = [];
	formGroup: FormGroup;
	isTestingEnvironment: boolean;

	constructor(
		private router: Router,
		private appConfig: AppConfigService,
		private translate: TranslateApplicationService,
		private employeeService: EmployeeService,
		private siteService: SitesService,
		private formBuilder: FormBuilder,
		private activedirectoryService: ActiveDirectoryService,
		private appNavigationService: NavigationService,
		private applications: ApplicationInfoService,
		private cognitoService: CognitoService,
		private environmentsService: EnvironmentsService,
	) {
		this.formGroup = this.formBuilder.group({
			fksite: [null, Validators.required],
		});

		this.applications.getApplicationByName().subscribe(
			(result: Application) => {
				result.languages.forEach((p) => {
					let language = {
						id: p.id,
						title: p.language,
						flag: p.code,
						applicationId: result.id,
					};
					this.languages.push(language);
				});
				this.SelectLanguage();
			},
			(error) => {},
		);

		router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.showLoadingBar = true;
			}
			if (event instanceof NavigationEnd) {
				this.showLoadingBar = false;
			}
		});

		this.appConfig.onSettingsChanged.subscribe((settings) => {
			this.horizontalNav = settings.layout.navigation === 'top';
		});
		if (this.cognitoService.IsAuhtenticated()) {
			this.cognitoService.getUserInfo().subscribe((result) => {
				result.data;
				this.username = result['email'];
				let isDev = window['configuration'].isDev;
				let userNT = '';

				if (isDev) {
					//DEV
					userNT = result['cognito:username'];
					userNT = userNT.slice(5);
				} else {
					// STG || PRD
					userNT = result['custom:samaccountname'];
					
				}

				let email = result['email'];
				localStorage.setItem('email', email);

				this.GetWorkdayId(userNT);

				this.appNavigationService.setNavigationModel(
					new AppNavigationModel(),
				);
			});
			this.appNavigationService.setNavigationModel(
				new AppNavigationModel(),
			);
		} else {
			this.appNavigationService.setNavigationModel(
				new AppNavigationModel(),
			);
		}
	}

	GetWorkdayId(usernt: any) {
		let arr: IEmployeesInActiveDirectory[] = [];
		this.activedirectoryService
			.GetEmployeeByEmailNumberName(usernt)
			.subscribe(
				(result: TransactionResult<IEmployeesInActiveDirectory[]>) => {
					if (result.success) {
						
						let jsonObj = JSON.parse(result.data.toString());
						arr = jsonObj.data;

						this.GetUserInfoWorkday(arr[0].workdayId);
					}
				},
			);
	}

	GetUserInfoWorkday(workdayid: any) {
		this.employeeService
			.IsAdmin()
			.subscribe((resultAdmin: TransactionResult<IRoles>) => {
				if (resultAdmin.success) {
					
					if (
						resultAdmin.data[0].role == 'Administrator' ||
						resultAdmin.data[0].role == 'HR'
					) {
						localStorage.setItem('role', resultAdmin.data[0].role);
						this.isAdminUser = true;
						this.GetSites(this.isAdminUser);
					} else {
						localStorage.setItem('role', resultAdmin.data[0].role);
						this.isAdminUser = false;
						this.employeeService
							.GetUserInfo(workdayid)
							.subscribe(
								(
									result: TransactionResult<
										any[]
									>,
								) => {
									if (result.success) {
										result.data;
										
										localStorage.setItem(
											'fksite',
											result.data[0].pk_site,
										);
										localStorage.setItem(
											'paygrade',
											result.data[0].compGrade
												.trim()
												.toLowerCase(),
										);
										localStorage.setItem(
											'payratetype',
											result.data[0].pay_rate_type
												.trim()
												.toLowerCase(),
										);
										localStorage.setItem(
											'jobcategory',
											result.data[0].job_category
												.trim()
												.toLowerCase(),
										);
										localStorage.setItem(
											'employeetype',
											result.data[0].labor
												.trim()
												.toLowerCase(),
										);
										this.GetSites(this.isAdminUser);
									}
								},
							),
							(error: any) => {};
					}
				}
			});
	}

	GetSites(isadmin: boolean) {
		
		this.listSites = [];
		let first = true;
		if (localStorage.getItem('role') == 'General') {
			this.IsGeneralRol();
		} else {
			this.siteService.GetSitesByUsername().subscribe(
				(result: TransactionResult<ISites[]>) => {
					if (result.data.length > 0) {
						
						first = this.SetFirstRole(result, isadmin, first);
					} else
						this.router.navigateByUrl(
							'/main/without-system-permission',
						);
				},
				(error: any) => {},
			);
		}
	}

	private SetFirstRole(result: TransactionResult<ISites[]>, isadmin: boolean, first: boolean) {
		result.data.forEach((obj) => {
			if (obj.fk_record_status == 1) {
				if (isadmin) {
					first = this.SetFirtSite(first, obj);
				}
				else {
					this.OtherRol(obj);
				}
				this.listSites.push(obj);
			}
		});
		/*La siguiente linea crea todo el menu */
		this.appNavigationService.setNavigationModel(
			new AppNavigationModel());
		return first;
	}

	private SetFirtSite(first: boolean, obj: ISites) {
		if (first) {
			localStorage.setItem(
				'fksite',
				obj.pk_site.toString());
			this.formGroup.controls.fksite.setValue(
				obj.pk_site);
			this.GetLenguage();
		}
		first = false;
		return first;
	}

	private OtherRol(obj: ISites) {
		if (localStorage.getItem('fksite') != 'N/A') {
			this.formGroup.controls.fksite.setValue(
				localStorage.getItem('fksite'));
			this.GetLenguage();
		}
		this.formGroup.controls.fksite.setValue(
			obj.pk_site);
		this.GetLenguage();
	}

	private IsGeneralRol() {
		if (localStorage.getItem('fksite') != 'N/A') {
			this.siteService
				.IsActiveSite()
				.subscribe((result: TransactionResult<ISites>) => {
					if (result.success) {
						if (result.data.fk_record_status == 1) {
							this.formGroup.controls.fksite.setValue(result.data.pk_site);
							this.listSites.push(result.data);
							this.GetLenguage();
						}
					}
				});
		}
	}

	ChangeSite() {
		if (this.cognitoService.IsAuhtenticated()) {
			localStorage.setItem(
				'fksite',
				this.formGroup.controls.fksite.value,
			);
			jQuery('.btnReload').trigger('click');
			/*La siguiente linea crea todo el menu */
			this.appNavigationService.setNavigationModel(
				new AppNavigationModel(),
			);
		}
	}

	GetLenguage() {
		this.siteService
			.GetCurrentSite(localStorage.getItem('fksite'))
			.subscribe((result: TransactionResult<ISites[]>) => {
				if (result.success) {
					this.currentSite = result.data;
					let currentSite = this.currentSite.filter(
						(element) =>
							element.pk_site ==
							this.formGroup.controls.fksite.value,
					);
					let changeToLanguage = this.languages.filter(
						(element) => element.title == currentSite[0].language,
					);
					this.SetLanguage(changeToLanguage[0]);
				}
			});
	}

	SelectLanguage(): void {
		let indexLang = 0;
		if (this.languages != undefined) {
			if (navigator.language.split('-').length > 0) {
				indexLang = this.languages.findIndex(
					(lang) => lang.flag === navigator.language.split('-')[0],
				);
			} else {
				indexLang = this.languages.findIndex(
					(lang) => lang.flag === navigator.language,
				);
			}

			if (indexLang < 0) {
				this.selectedLanguage = this.languages[0];
			} else {
				this.selectedLanguage = this.languages[indexLang];
			}

			this.SetLanguage(this.selectedLanguage);
		}
	}

	SetLanguage(lang) {
		// Set the selected language for toolbar
		this.selectedLanguage = lang;
		// selected language for translations
		this.translate.use(lang);
	}

	logOut(): void {
		localStorage.removeItem('email');
		localStorage.removeItem('fksite');
		localStorage.removeItem('role');
		this.cognitoService.logOut();
	}
}
