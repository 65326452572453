import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { IProperties } from '../Interfaces/IProperties';
import { GeneralServices } from '../services/general-services';
import { ILastReleases } from '../Interfaces/ILastReleases';

@Injectable({
	providedIn: 'root',
})
export class PropertiesService extends RestCallService {
	public dataToSend: IProperties = {
		pk_property: 0,
		fk_property_data_type: 8,
		fk_property_group: 1,
		fk_text: 0,
		property: '',
		property_value: '',
		description: '',
		editable: true,
		visible: true,
		cached: true,
		fk_user_updater: 1,
		last_updated: new Date(),
		available: true,
		fk_site_logged_user: 0,
		username: '',
	};

	constructor(
		public httpClient: HttpClient,
		public toastr: ToastrService,
		public generalServices: GeneralServices,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(
			httpClient,
			'API_Benefits',
			toastr,
			notificationService,
			translate,
		);
	}

	public GetProperties() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.get<IProperties[]>(
			'/api/Properties/GetProperties',
			this.dataToSend,
		);
	}

	public GetPropertyByName(propertyName: string) {
		this.generalServices.ValidateCognito();
		this.dataToSend.property = propertyName;
		//this.dataToSend.fk_site_logged_user = parseInt(localStorage.getItem('fksite'));
		return this.post<IProperties[]>(
			'/api/Properties/GetPropertyByName',
			this.dataToSend,
		);
	}

	public GetLastReleases() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<ILastReleases[]>(
			'/api/Properties/GetLastReleases',
			this.dataToSend,
		);
	}
}
