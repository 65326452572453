import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IEnvironments } from '../Interfaces/IEnvironments';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { GeneralServices } from '../services/general-services';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentsService extends RestCallService {
	public dataToSend: IEnvironments = {
		pk_environment: 0,
		environment: '',
		description: '',
		normal_emails: false,
		not_normal_emails: '',
		active: false,
		fk_site_logged_user: 0,
		username: '',
	};

	constructor(
		public httpClient: HttpClient,
		private generalServices: GeneralServices,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(
			httpClient,
			'API_Benefits',
			toastr,
			notificationService,
			translate,
		);
	}

	public GetActiveEnvironment() {
		this.generalServices.ValidateCognito();
		return this.post<IEnvironments[]>(
			'/api/Environments/GetActiveEnvironment',
			this.dataToSend,
		);
	}
}
